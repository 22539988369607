import React from "react"
import { Link } from "gatsby"
import DispayLeafletMap from "../../components/displaymap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Carousel from "nuka-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { setZone, setPickupPoint } from "../../actions/panier"


import groceriesIcon from "../../images/icon/paper-bag.svg"

export const TheyPage = props => {
  const data = useStaticQuery(graphql`
    query imagesThey {
      they_1: file(relativePath: { eq: "producteur/they_1.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      they_2: file(relativePath: { eq: "producteur/they_2.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      they_3: file(relativePath: { eq: "producteur/they_3.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      they_4: file(relativePath: { eq: "producteur/they_4.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      livre: file(relativePath: { eq: "producteur/livre-they.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      allMysqlGroupe {
        edges {
          node {
            group_id
            group_ville
            group_zone_id
          }
        }
      }
    }
  `)

  const panier = useSelector(state => state.panierR)

  const latitude = 47.383245301076734
  const longitude = 6.066252539794959

  const state = {
    markers: [{ key: "marker1", position: [latitude, longitude] }],
    latlng: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 9,
  }
  const zones = data.allMysqlGroupe.edges.filter(mark => mark.node.group_zone_id === 12)
  const dispatch = useDispatch()


  return (
    <Layout>
      <SEO title="Ferme Bio de They" />
      <section className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-1">La ferme Bio de They</h1>
          </div>
        </div>
      </section>
      <section className="pt-5 mt-5">
        <div className="container is-fluid">
          <div className="columns">
            <div className="column is-one-third">
              <h2 className="almond is-size-3 has-text-centered">
                They-en-Sorans (70190)
              </h2>
              {typeof window !== "undefined" && (
                <DispayLeafletMap
                  style={{ height: "600px" }}
                  markers={state.markers}
                  center={state.latlng}
                  zoom={state.zoom}
                />
              )}
            </div>
            <div className="column">
              <div>
                <Carousel
                  autoplay={true}
                  autoplayInterval={2500}
                  transitionMode={"fade"}
                  wrapAround={true}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button
                      onClick={previousSlide}
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <FontAwesomeIcon
                        className=""
                        icon={faChevronLeft}
                        size="3x"
                        color={"whitesmoke"}
                      />
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button
                      onClick={nextSlide}
                      style={{ backgroundColor: "transparent", border: "none" }}
                    >
                      <FontAwesomeIcon
                        className=""
                        icon={faChevronRight}
                        size="3x"
                        color={"whitesmoke"}
                      />
                    </button>
                  )}
                >
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.they_3.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.they_2.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.they_1.childImageSharp.fluid}
                  />
                  <Img
                    style={{ maxHeight: "500px" }}
                    fluid={data.they_4.childImageSharp.fluid}
                  />
                </Carousel>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div>
                <section className="m-2 has-text-justified is-size-4">
                  <div className="box" style={{ background: "#ffff76" }}>
                    <p className="is-4">
                      Labellisée en Agriculture Biologique depuis 1996, une
                      douzaine de personnes y travaillent.
                    </p>
                    <p className="is-4">
                      Nous élevons des vaches laitières, des poulets de chair et
                      poules pondeuses, des porcs et quelques chevaux sur 180
                      ha, principalement composé de prairies, et de céréales
                      bio.
                    </p>
                    <p className="is-4">
                      Sur la ferme, nous produisons également ; du blé servant à
                      la fabrication (grâce à une meule de pierre) de farine de
                      blé panifiable (T.80 ou T.150), des œufs, des pommes de
                      terre en plein champs, du lait cru entier, de la viande de
                      veau, de bœuf, de porc et de la volaille, mais aussi toute
                      la charcuterie de porc (saucisse, terrine, boudin,
                      chorizo, etc), transformée sur place par notre boucher.
                    </p>
                    <p className="is-4">
                      {" "}
                      Dans le magasin de vente direct situé dans la cour de la
                      ferme, sont proposés de nombreux produits locaux
                      (fromages, miel, jus de fruits, vin, confitures, huiles,
                      légumes, tisanes …) mais aussi toute une gamme de produits
                      biologiques en tous genres (fruits, chocolats, savons,
                      légumineuses, boissons…). Visites libres et gratuites de
                      la ferme toute l'année durant les horaires d'ouverture du
                      magasin{" "}
                    </p>
                  </div>
                </section>
              </div>
            </div>
            <div className="column has-text-centered">
              {(panier.zone === null && panier.pickupPoint === null) || panier.zone !== 12 ? null : (
                <Link to={`/producteur/12-fermedethey/produits`}>
                  <button className="button is-success is-light is-large mt-4 dreamland is-size-3">
                    <img
                      src={groceriesIcon}
                      style={{
                        width: 40,
                        height: 40,
                        position: "relative",
                        top: 12,
                        marginRight: 15,
                      }}
                      alt="Produits"
                    />
                    Voir les produits
                  </button>
                </Link>
              )}
              <div>
                  <span
                    className="mt-5 ml-4 almond is-size-3"
                    style={{ display: "block" }}
                  >
                    Ce producteur livre aux points de dépôt suivants :
                  </span>
                  <div>
                    {zones && zones.map(zone => (
                      <Link to={`/producteur/12-fermedethey/produits`} onClick={() => {dispatch(setPickupPoint(zone.node.group_id));dispatch(setZone(12))}} className="button is-success is-light is-large dreamland is-size-3 mr-2" key={zone.node.group_id}>
                        {zone.node.group_ville}
                      </Link>
                    ))}
                  </div>
                </div>
            </div>
          </div>
          <div className="columns mt-5 pt-5 pb-5 pattern-cross-dots">
            <div className="column is-5 pl-5 has-text-centered">
              <Img
                style={{ maxHeight: "500px" }}
                fluid={data.livre.childImageSharp.fluid}
                alt="Livre They"
              />
              <p>126 pages (photos couleurs & textes)<br/>format 28X28cm - couverture cartonnée&nbsp;+&nbsp;jaquette - poids 1.123&nbsp;gr&nbsp;-&nbsp;29&nbsp;€.</p>
              <p style={{fontStyle: "italic", color: "#000"}}>Disponible à la boutique de la ferme bio de They et en magasins bio de Franche-Comté.</p>
              <p style={{fontStyle: "italic", color: "#000"}}>Ou livraison postale en France-métropolitaine et suivi colis&nbsp;: 8,60&nbsp;€<br />
                Commande en direct à l'adresse de l'auteur : Gérard GARCIA - 1, route de Bay 70150 CULT<br />
                Contact : gerard.garcia770@orange.fr ou au 06 74 87 62 32</p>
              <p>Merci de votre intérêt pour cet ouvrage et bio...logiquement vôtre. Bonne lecture !</p>
            </div>
            <div className="column pl-5">
              <h3 className="title almond">Le livre &laquo;&nbsp;La ferme bio de They, voyage au bout d'un rève&nbsp;&raquo;</h3>
              <p>Ecolos purs et durs au sens politique du terme ? Certainement pas ! Nous n'appartenons à aucune chapelle.</p>
              <p>Fervents défenseurs de notre environnement, des richesses des espaces naturels et des traditions patrimoniales est de circonstance pour décrire notre démarche.</p>
              <p>Adeptes de la &laquo;&nbsp;bonne-bouffe&nbsp;&raquo;, respectueuse de l'humain, des animaux et végétaux est plutôt notre crédo.</p>
              <p>La réalisation de cet ouvrage a tout d'abord consisté à retracer l'évolution de l'agriculture à travers les âges. D'en situer les découvertes, les inventions mais aussi les contradictions pour déboucher sur des pratiques respectueuses de l'écosystème.</p>
              <p>Comment alors pour nous de ne pas répondre à cette opportunité, totalement fortuite, de faire connaissance avec cette Ferme bio de They (Haute-Saône), dont les précurseurs et leur génération suivante ont su en faire un espace agricole référant à travers un parcours atypique et d'une prise de conscience progressive les amenant à une agriculture biologique responsable et en quasi-autonomie énergétique qui allie agrobiologie et aspects économiques.</p>
              <p>Un milieu autarcique où partis de rien, ils sont parvenus à un tout par une agriculture qui respecte le vivant, là où la nature peut être avant tout un lieu de vie.</p>
              <p>Alors que nos pas ont usé moult souliers dans de nombreuses régions françaises et même au-delà de nos frontières, nous avions là en Franche-Comté, tout près de chez nous, l'occasion de découvrir un cheminement professionnel reposant sur une logique naturelle et intellectuelle basée sur une pratique agricole garante du bien-être à vivre, à travailler dignement et proprement la terre et ne proposant que des produits sains.</p>
              <p>Cette expérience formatrice où les rencontres émotionnelles n'ont d'égal qu'un climat apaisant nous a permis de comprendre que le désir peut devenir réalité. Un &laquo;&nbsp;Voyage au bout d'un rêve&nbsp;&raquo;.</p>
              <p>Cet ouvrage est un mariage entre textes et photos qui le situe dans la catégorie éditoriale des &laquo;&nbsp;beaux livres&nbsp;&raquo;</p>
              <p>Pourtant réalisé en auto-édition, il se veut accessible au plus grand nombre de lecteurs.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TheyPage
